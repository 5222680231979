/*!
 * baguetteBox.js
 * @author  feimosi
 * @version 1.9.1
 * @url https://github.com/feimosi/baguetteBox.js
 */
#baguetteBox-overlay {
    display: none;
    opacity: 0;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    background-color: #222;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-transition: opacity .5s ease;
    transition: opacity .5s ease; }
#baguetteBox-overlay.visible {
    opacity: 1; }
#baguetteBox-overlay .full-image {
    display: inline-block;
    position: relative;
    width: 100%;
    height: calc(100% - 10rem);
    margin-top: 4rem;
    text-align: center; }
#baguetteBox-overlay .full-image figure {
    display: inline;
    margin: 0;
    height: 100%; }
#baguetteBox-overlay .full-image img {
    display: inline-block;
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    vertical-align: middle; }
#baguetteBox-overlay .full-image figcaption {
    display: block;
    width: 100%;
    text-align: center;
    line-height: 1.8;
    white-space: normal;
    color: var(--color-white); }
#baguetteBox-overlay .full-image:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 1px;
    margin-right: -1px; }

#baguetteBox-slider {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    white-space: nowrap;
    -webkit-transition: left .4s ease, -webkit-transform .4s ease;
    transition: left .4s ease, -webkit-transform .4s ease;
    transition: left .4s ease, transform .4s ease;
    transition: left .4s ease, transform .4s ease, -webkit-transform .4s ease, -moz-transform .4s ease; }
#baguetteBox-slider.bounce-from-right {
    -webkit-animation: bounceFromRight .4s ease-out;
    animation: bounceFromRight .4s ease-out; }
#baguetteBox-slider.bounce-from-left {
    -webkit-animation: bounceFromLeft .4s ease-out;
    animation: bounceFromLeft .4s ease-out; }

@-webkit-keyframes bounceFromRight {
    0% {
        margin-left: 0; }
    50% {
        margin-left: -30px; }
    100% {
        margin-left: 0; } }

@keyframes bounceFromRight {
    0% {
        margin-left: 0; }
    50% {
        margin-left: -30px; }
    100% {
        margin-left: 0; } }

@-webkit-keyframes bounceFromLeft {
    0% {
        margin-left: 0; }
    50% {
        margin-left: 30px; }
    100% {
        margin-left: 0; } }

@keyframes bounceFromLeft {
    0% {
        margin-left: 0; }
    50% {
        margin-left: 30px; }
    100% {
        margin-left: 0; } }

.baguetteBox-button#next-button, .baguetteBox-button#previous-button {
    top: 50%;
    top: calc(50% - 30px);
    width: 20px;
    height: 35px; }

.baguetteBox-button {
    position: absolute;
    cursor: pointer;
    outline: none;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    color: #ddd; }
.baguetteBox-button:focus, .baguetteBox-button:hover {
    color: var(--color-white); }
.baguetteBox-button#next-button {
    right: 2%; }
.baguetteBox-button#previous-button {
    transform: rotate(180deg);
    left: 2%; }
.baguetteBox-button#close-button {
    top: 14px;
    right: 2%;
    right: calc(2% + 6px);
    width: 78px;
    height: 19px; }
.baguetteBox-button svg {
    position: absolute;
    left: 0;
    top: 0; }

/*
    Preloader
    Borrowed from http://tobiasahlin.com/spinkit/
*/
.baguetteBox-spinner {
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px; }

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
    width: 100%;
    height: 100%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #fff;
    opacity: .6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: bounce 2s infinite ease-in-out;
    animation: bounce 2s infinite ease-in-out; }

.baguetteBox-double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s; }

@-webkit-keyframes bounce {
    0%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0); }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1); } }

@keyframes bounce {
    0%, 100% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        transform: scale(0); }
    50% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        transform: scale(1); } }
