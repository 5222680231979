$list-custom-color-dots: true;

ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

%list {
    margin-bottom: 1em;
    overflow: hidden;
    padding-left: 1.8em;

    > li {
        display: list-item;
        margin-bottom: .67em;
    }

    &.-list-style-none {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
    }
}

// Lists without class can get a default styling
ul:not([class]), ol:not([class]), .list {
    @extend %list;
    letter-spacing: .02rem;
}

ul:not([class]), ul.list {
    list-style-type: disc;

    @if $list-custom-color-dots {
        list-style: none;

        > li {
            position: relative;

            &::before {
                background-color: currentColor;
                border-radius: 50%;
                content: '';
                display: block;
                height: .7rem;
                left: -1em;
                position: absolute;
                top: .45em;
                width: .7rem;
            }
        }
    }
}

ol:not([class]), ol.list {
    list-style-type: decimal;
}
