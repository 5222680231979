%title {
    display: block;
    font-weight: var(--font-weight-extra-bold);
    line-height: 1.35;
    margin-bottom: .9em;
    margin-top: 0;
    position: relative;
    text-transform: uppercase;

    a {
        color: inherit;
        text-decoration: none;

        &:hover, &:focus, &:active {
            color: var(--color-secondary);
            text-decoration: none;
        }
    }

    &.-align-center {
        text-align: center;
    }

    &.-align-left {
        text-align: left;
    }

    &.-color-line-up {
        color: var(--color-line-up);
    }
}

.title--1, h1:not([class]) {
    @extend %title;

    @include responsive-size(font-size, 25, 30);
    @include responsive-size(padding-bottom, 34 ,38);
    @include responsive-size(padding-top, 33 ,38);
    @include responsive-size(padding-left, $inner-container-padding-min, $inner-container-padding-max);
    @include responsive-size(padding-right, $inner-container-padding-min, $inner-container-padding-max);
    background-color: var(--color-action);
    color: var(--color-black);
    margin-bottom: 0;
    text-align: center;

    &.-bgcolor-camping {
        background-color: var(--color-camping);
    }

    &.-bgcolor-blue {
        background-color: var(--color-blue);
    }

    &.-bgcolor-pink {
        background-color: var(--color-pink);
    }

    &.-bgcolor-transparent {
        background-color: transparent;
        color: var(--color-white);
        padding-left: 0;
        padding-right: 0;
    }

    &.-color-orange {
        color: var(--color-brown);
    }

    &.-color-yellow {
        color: var(--color-yellow);
    }
}

.title--1-alt {
    @extend %title;

    @include responsive-size(font-size, $font-size-ml, $font-size-l);
    margin-bottom: 1.7em;
    text-align: center;

    &.-small-margin {
        margin-bottom: 1.6rem;
    }
}

.title--2, h2:not([class]) {
    @extend %title;
    @include responsive-size(font-size, $font-size-m, $font-size-ml);

    &.-color-yellow {
        color: var(--color-yellow);
    }
}

.title--3, h3:not([class]) {
    @extend %title;
    @include responsive-size(font-size, $font-size-s, $font-size-m);
}

.title--4, h4:not([class]) {
    @extend %title;
    @include responsive-size(font-size, $font-size-xs, $font-size-s);
    margin-bottom: 0;
}
