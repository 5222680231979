.filter-category {
    border: 0;
    padding: 0;
    position: relative;
}

.filter-category__title {
    box-sizing: border-box;
    margin: 0;
    width: 22.2rem;

    button {
        background: var(--color-white);
        border: 0;
        box-sizing: border-box;
        color: var(--color-black);
        cursor: pointer;
        display: block;
        font-size: var(--font-size-m);
        font-weight: var(--font-weight-extra-bold);
        padding: .7rem 4.5rem .5rem 1.8rem;
        position: relative;
        text-align: left;
        text-transform: uppercase;
        width: 100%;

        &::after {
            background-image: url('../../images/options.svg');
            background-size: 100%;
            content: '';
            display: block;
            height: 2rem;
            position: absolute;
            right: 1.4rem;
            top: 50%;
            transform: translateY(-50%);
            width: 2.5rem;
        }
    }

    &.-views {
        button::after {
            background-image: url('../../images/arrow.svg');
            background-size: contain;
            transform: rotate(90deg);
            transform-origin: center;
            transition: .15s transform ease-in-out;
            width: 1.2rem;
            top: 1.3rem;

            .-open & {
                transform: rotate(-90deg) translateX(.2rem);
            }
        }
    }
}

.filter-category__options {
    background-color: var(--color-white);
    box-sizing: border-box;
    display: none;
    width: 22.2rem;

    .filter-category.-open & {
        display: block;
        position: absolute;
        right: 0;
        top: 3.9rem;
        z-index: var(--z-index-filter-dropdown);
    }
}

.filter-option__input {
    display: none;
}

.filter-option__label {
    border-top: 1px solid var(--color-black);
    color: var(--color-black);
    cursor: pointer;
    display: block;
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-medium);
    line-height: 1;
    padding: .9rem 1.8rem;
    text-decoration: none;

    &::before {
        border: .2rem solid var(--color-black);
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: 1.4rem;
        position: relative;
        top: .1rem;
        width: 1.4rem;

        .filter-option__input.-checkbox:checked + & {
            background: var(--color-black);
        }
    }

    &.-disabled {
        opacity: .5;
        text-decoration: line-through;

        &::before {
            opacity: .5;
        }
    }
}

.filter-option__label__count {
    font-size: 1.4rem;
}

.filter__submit {
    display: none;
}

@import 'filter-selection';
@import 'filter__result';
