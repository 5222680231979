html {
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font-primary);
    font-size: 62.5%; // Work with 10px scale for rems
    scroll-behavior: smooth;

    @media screen and (prefers-reduced-motion: reduce) {
        scroll-behavior: auto;
    }

    &.-is-mobile-menu-open {
        @media (--show-mobile-menu) {
            height: 100%;
            overflow: hidden;
        }
    }
}
