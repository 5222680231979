.schedule-heading {
    padding: 0;
    position: relative;
}

.schedule-heading__title {
    margin: 0;
    padding: 0;
    text-align: center;
}

.schedule-heading__menu {
    float: none;
}

@media (--schedule-hours-desktop) {
    .schedule-heading {
        display: block;
        position: relative;
    }

    .schedule-heading__menu {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
