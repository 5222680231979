.h-margin-top-4-responsive {
    @include responsive-size(margin-top, 10, 40);
}

.h-margin-top-4 {
    margin-top: 4rem;
}

.h-margin-bottom-5 {
    margin-bottom: 5rem;
}

.h-page-margin-bottom {
    @include responsive-size(margin-bottom, 20, 60);
}
