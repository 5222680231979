.countdown {
    display: flex;
    justify-content: space-around;
    margin-bottom: 3rem;
    max-width: 60rem;
    min-width: 32rem;
    width: 30vw;
}

.countdown__item {
    @include font-size(4.5rem);
    font-weight: var(--font-weight-extra-bold);
    text-align: center;

    span {
        @include font-size(1.8rem);
        display: block;
        font-weight: var(--font-weight-medium);
    }
}
