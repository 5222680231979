@custom-media --camping-blocks-multiple-columns (width > 700px);
@custom-media --camping-blocks-no-side-margin (width < 380px);

.camping-blocks {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    @include responsive-size(margin-bottom, $inner-container-padding-min, $inner-container-padding-max);
    @include responsive-size(margin-top, $inner-container-padding-min, $inner-container-padding-max);

    @media (--camping-blocks-multiple-columns) {
        margin-bottom: 7.5rem;
        margin-top: 5rem;
    }

    @media (--camping-blocks-no-side-margin) {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
}

.camping-blocks__item {
    align-items: center;
    background-color: var(--color-camping);
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-extra-bold);
    justify-content: space-between;
    margin: 1rem;
    max-width: 41.3rem;
    text-align: center;
    width: 100%;
    @include responsive-size(margin-left, $inner-container-padding-min, $inner-container-padding-max);
    @include responsive-size(margin-right, $inner-container-padding-min, $inner-container-padding-max);

    @media (--camping-blocks-multiple-columns) {
        margin-left: 1rem;
        margin-right: 1rem;
        max-width: 32.3rem;
    }

    @media (--camping-blocks-no-side-margin) {
        margin-left: 0;
        margin-right: 0;
    }

    .button.-transparent {
        color: currentColor;
        border-color: currentColor;
    }

    &.-bgcolor-luxe {
        background-color: var(--color-blue);
    }

    &.-bgcolor-comfort {
        background-color: var(--color-pink);
        color: var(--color-black);

        .camping-blocks__item-ticket-button {
            &:hover, &:focus, &:active {
                background-color: var(--color-white);
            }
        }

        .button.-transparent {
            &:hover, &:focus, &:active {
                background-color: var(--color-white);
            }
        }
    }

    ul:not([class]) > li::before {
        content: none;
    }
}

.camping-blocks__item-inner {
    padding: 1rem 2rem 1.4rem;
    width: 100%;
}

.camping-blocks__item-title {
    align-items: flex-end;
    justify-content: center;
    display: flex;
    margin-top: 2rem;
    text-transform: uppercase;

    @media (--camping-blocks-multiple-columns) {
        min-height: 9rem; // Reserve room for 2 lines
    }
}

.camping-blocks__item-title, .camping-blocks__item-price {
    color: currentColor;
    flex: 0 0 auto;
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-extra-bold);

    > small {
        display: block;
        font-size: var(--font-size-s);
        font-weight: var(--font-weight-medium);
        text-transform: none;
    }
}

.camping-blocks__item-more-info {
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-semi-bold);
    max-height: 0;
    overflow: hidden;
    text-align: left;
    transition: max-height .4s ease-in-out, padding-top .3s ease-in-out;

    &.-is-open {
        max-height: 100rem;
        padding-top: 4rem;
    }
}

.camping-blocks__item-ticket-button {
    margin-top: 3rem;
}

.camping-blocks__image {
    flex: 0 0 auto; // IE fix
    width: 100%; // IE fix

    &.-sold-out {
        position: relative;
        overflow: hidden;

        .tape {
            transform: rotate(-9deg);
            position: absolute;
            bottom: 31px;
            left: -73px;
            transform-origin: left;
            width: 151%;
        }
    }
}

.camping-blocks__item-capacity {
    color: var(--color-black);
    line-height: 4.5rem;
    margin-bottom: 1rem;
}

.camping-blocks__item-toggle {
    appearance: none;
    background-color: transparent;
    border: 0;
    color: currentColor;
    display: inline-block;
    height: 3.3rem;
    margin: 0;
    overflow: hidden;
    position: relative;
    text-indent: -99rem;
    width: 3.3rem;

    &::after {
        background-image: url('../../images/arrow.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        content: '';
        display: block;
        height: 3.3rem;
        left: 0;
        position: absolute;
        top: 0;
        transform: rotate(90deg) translateY(-8px);
        transform-origin: center;
        transition: transform .4s ease-in-out;
        width: 1.8rem;

        @supports (mask-image: url('../../images/arrow.svg')) {
            background-color: currentColor;
            background-image: none;
            mask-image: url('../../images/arrow.svg');
            mask-repeat: no-repeat;
            mask-size: contain;
        }
    }

    &.-is-active {
        &::after {
            transform: rotate(-90deg) translateY(8px);
        }
    }

    &:focus, &:active {
        color: currentColor;
        outline: none;
    }
}
