.menu-toggle {
    appearance: none;
    background-color: var(--color-white);
    border: 0;
    display: none;
    height: 100%;
    padding: 0;
    border-radius: 0;

    &:focus {
        outline: none;
    }

    @media (--show-mobile-menu) {
        display: inline-block;
    }

    &:not(.-is-active) {
        svg {
            &:last-child {
                display: none;
            }
        }
    }

    &.-is-active {
        svg {
            &:first-child {
                display: none;
            }
        }
    }
}
