%form__field--base-reset {
    font-family: var(--font-primary);
    line-height: normal;
    margin: 0;
    padding: 1em;
    width: 100%;
}

%form__field {
    @extend %form__field--base-reset;

    background-color: var(--color-white);
    border: 1px solid var(--color-white);
    border-radius: 0;
    box-sizing: border-box;
    color: var(--color-black);
    display: block;
    padding: 1em .7em;
    word-break: normal;

    &::placeholder {
        color: var(--color-gray);
        font-style: normal;
        opacity: 1;
    }

    &:focus {
        background-color: var(--color-white);
        border-color: var(--color-primary);
        outline: none;
    }

    .form__entry--invalid & {
        // no styling needed
    }
}

input:not([class], [type='checkbox'], [type='radio'], [type='range']),
input[class='']:not([type='checkbox'], [type='radio'], [type='range']), // This is a fix for autocompletion by 1password or other plugins who generate an empty class attribute
.form__field {
    @extend %form__field;

    display: inline-block;
}

textarea:not([class]), .form__field--textarea {
    @extend %form__field;

    line-height: 1.5;
    padding: .8em 1em;
}
