@custom-media --main-logo-compact-logo (width < 1200px);

.main-logo {
    display: flex;
    flex: 1 0 auto;
    justify-content: space-between;
    max-width: 92rem;

    @media (--main-logo-compact-logo) {
        display: block;
        max-width: 24rem;
        position: relative;
        width: 100%;
    }
}

.main-logo__item {
    align-items: center;
    display: flex;

    @media (--main-logo-compact-logo) {
        height: auto;

        svg {
            display: block;
            max-width: 100%;
        }

        &:nth-child(1) {
            position: relative;
            width: 50%;
        }

        &:nth-child(2), &:nth-child(3) {
            height: 50%;
            left: 53%;
            position: absolute;
            width: 44%;
        }

        &:nth-child(2) {
            top: 0;
        }

        &:nth-child(3) {
            bottom: 0;
            width: 50%;
        }
    }

    .-is-mobile-menu-open & {
        &:not(:first-child) {
            @media (--show-mobile-menu) {
                display: none;
            }
        }
    }
}
