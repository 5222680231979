.language-switch {
    margin-left: -1em;
    margin-right: -1em;

    a {
        display: inline-block;
        font-weight: var(--font-weight-semi-bold);
        padding: 0 1em;
        position: relative;
        text-decoration: none;

        &:not(:last-child)::after {
            background-color: var(--color-white);
            content: '';
            height: 100%;
            position: absolute;
            right: -.1rem;
            top: 0;
            width: .1rem;
        }

        &:hover, &:focus, &:active {
            color: var(--color-action);
        }
    }
}
