.social-icons {
    align-items: center;
    display: flex;
    margin-bottom: 2em;

    &.-color-pink {
        color: var(--color-blue);
    }

    &.-no-margin-bottom {
        margin-bottom: 0;
    }
}

.social-icons__item {
    display: inline-block;
    transition: color .2s ease-in-out;

    &:hover, &:focus, &:active {
        color: var(--color-action);
    }

    &:not(:last-child) {
        margin-right: 3.8rem;
    }

    svg {
        height: 2.2rem;
        width: 2.2rem;
    }
}
