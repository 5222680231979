@custom-media --button-make-bigger (width > 750px);

%button {
    background-color: var(--color-action);
    border: 1px solid var(--color-action);
    color: var(--color-black);
    cursor: pointer;
    display: inline-block;
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-extra-bold);
    line-height: 1;
    margin-bottom: 2rem;
    padding: .80rem 2rem .90rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .2s ease-in-out, color .2s ease-in-out, border-color .2s ease-in-out;

    @media (--button-make-bigger) {
        &:not(.-secondary) {
            padding-bottom: 1.4rem;
            padding-top: 1.3rem;
        }
    }

    &.-no-margin {
        margin-bottom: 0;
    }

    &.-transparent {
        background-color: transparent;
        border: 1px solid var(--color-white);
        color: var(--color-white);
    }

    &:hover, &:focus, &:active {
        background-color: var(--color-pink);
        border-color: var(--color-pink);
        color: var(--color-black);
        text-decoration: none;
    }

    &.-filter {
        background-color: var(--color-white);
        border-color: var(--color-white);
        margin-bottom: 0;
        padding-top: 1rem;
        padding-bottom: 1rem;

        &:hover, &:focus, &:active {
            background-color: var(--color-pink);
            border-color: var(--color-pink);
        }
    }
}

button:not([class]), .button {
    @extend %button;

    &.-padding-large {
        padding: 1.45rem 4rem;
    }
}

@supports (mask-image: url('../../../images/print.svg')) {
    .button.-icon-print {
        &::after {
            content: '';
            display: inline-block;
            height: 1.9rem;
            width: 1.9rem;
            margin-left: .5em;
            background-color: currentColor;
            mask-image: url('../../../images/print.svg');
            mask-repeat: no-repeat;
            mask-size: contain;
            position: relative;
            top: .2rem;
        }
    }
}

.button.-back {
    background-color: transparent;
    border: 1px solid var(--color-white);
    color: var(--color-white);
    padding-left: 4.4rem;

    &:hover, &:focus, &:active {
        background-color: var(--color-pink);
        border-color: var(--color-pink);
        color: var(--color-black);
        text-decoration: none;

        &::after {
            @include target-ie() {
                background-image: url('../../../images/arrow.svg');
            }
        }
    }

    &::after {
        background-image: url('../../../images/arrow-white.svg');
        background-size: 100% 100%;
        content: '';
        display: block;
        height: 1.9rem;
        left: 1.2rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) scaleX(-1);
        width: 1rem;

        @supports (mask-image: url('../../../images/arrow.svg')) {
            background-color: currentColor;
            background-image: none;
            mask-image: url('../../../images/arrow.svg');
            mask-repeat: no-repeat;
            mask-size: contain;
        }
    }
}
