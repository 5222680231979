@custom-media --lineup-menu-row (width >= 600px);
@custom-media --lineup-menu-column (width < 600px);

.lineup-menu {
    @media (--lineup-menu-row) {
        display: flex;
    }

    @media (--categories-menu-line-up-mobile) {
        padding-bottom: 3rem;
        padding-top: 1rem;
    }
    @media (--lineup-menu-column) {
        padding-bottom: 2rem;
        padding-top: 0;
    }
}

.lineup-menu__views {
    @media (--lineup-menu-row) {
        display: inline-block;

        &:not(:first-child) {
            margin-left: 1rem;
        }

        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    @media (--lineup-menu-column) {
        margin: 1rem 0;
    }
}
