@custom-media --band-videos-one-row (width < 800px);

.band-videos {
    align-items: center;
    display: flex;
    justify-items: center;
    margin-top: -4rem;
    max-width: calc(91.8rem + 8rem);
    width: 100%;
    flex-wrap: wrap;

    @media (--band-videos-one-row) {
        flex-direction: column;
        margin-top: 0;
    }
}

.band-videos__item {
    margin: 4rem auto;
    max-width: 100%;
    width: calc(100% / 2 - 4rem);

    @media (--band-videos-one-row) {
        margin-top: 0;
        width: 100%;
    }
}

.band-videos__embed {
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;

    > iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
