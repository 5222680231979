@custom-media --lineup-stage-5-columns (width >= 1750px);
@custom-media --lineup-stage-4-columns (width >= 1400px) and (width < 1750px);
@custom-media --lineup-stage-3-columns (width >= 1050px) and (width < 1400px);
@custom-media --lineup-stage-2-columns (width >= 700px) and (width < 1050px);
@custom-media --lineup-stage-1-columns (width < 700px);

:root {
    --lineup-stage-color: var(--color-green);
}

.lineup-stages {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(35rem, 1fr));

    &.-donderdag {
        --lineup-stage-color: var(--color-lineup-thursday);
    }

    &.-vrijdag {
        --lineup-stage-color: var(--color-lineup-friday);
    }

    &.-zaterdag {
        --lineup-stage-color: var(--color-lineup-saturday);
    }

    &.-zondag {
        --lineup-stage-color: var(--color-lineup-sunday);
    }
}

.lineup-stage {
    color: var(--color-white);
    background-color: var(--color-black);
    display: flow-root;

    @media (--lineup-stage-1-columns) {
        &:nth-child(2n) {
            @supports (mask-image: url('../../images/stages/bg-boiler-room.svg')) {
                color: var(--color-black);
                background-color: var(--lineup-stage-color);
            }
        }
    }

    @media (--lineup-stage-2-columns) {
        &:nth-child(4n-1), &:nth-child(4n-2) {
            @supports (mask-image: url('../../images/stages/bg-boiler-room.svg')) {
                color: var(--color-black);
                background-color: var(--lineup-stage-color);
            }
        }
    }

    @media (--lineup-stage-3-columns) {
        &:nth-child(2n) {
            @supports (mask-image: url('../../images/stages/bg-boiler-room.svg')) {
                color: var(--color-black);
                background-color: var(--lineup-stage-color);
            }
        }
    }

    @media (--lineup-stage-4-columns) {
        &:nth-child(8n-6), &:nth-child(8n-4), &:nth-child(8n-3), &:nth-child(8n-1) {
            @supports (mask-image: url('../../images/stages/bg-boiler-room.svg')) {
                color: var(--color-black);
                background-color: var(--lineup-stage-color);
            }
        }
    }

    @media (--lineup-stage-5-columns) {
        &:nth-child(2n) {
            @supports (mask-image: url('../../images/stages/bg-boiler-room.svg')) {
                color: var(--color-black);
                background-color: var(--lineup-stage-color);
            }
        }
    }
}

.lineup-stage__title {
    @extend .container-inner;
    align-items: center;
    display: flex;
    flex-basis: calc(100% / 3);
    justify-content: left;
    margin-top: 4rem;
    margin-bottom: 0;
    position: relative;
    text-transform: uppercase;
    text-align: center;
    z-index: 0;
    font-size: var(--font-size-m);

    &::before {
        margin-right: 1em;
    }

    &::before {
        background-repeat: no-repeat; // needed for no mask support
        background-size: 100%; // needed for no mask support
        content: '';
        display: inline-block;
        height: 5rem;
        width: 5rem;

        @supports (mask-image: url('../../images/stages/icon-main-stage.svg')) {
            background-color: currentColor;
            mask-repeat: no-repeat;
            mask-size: contain;
        }
    }

    &.-main-stage {
        &::before {
            background-image: url('../../images/stages/icon-main-stage.svg');

            @supports (mask-image: url('../../images/stages/icon-dance-hall.svg')) {
                background-image: none;
                mask-image: url('../../images/stages/icon-main-stage.svg');
            }
        }
    }

    &.-boiler-room {
        &::before {
            background-image: url('../../images/stages/icon-boiler-room.svg');

            @supports (mask-image: url('../../images/stages/icon-dance-hall.svg')) {
                background-image: none;
                mask-image: url('../../images/stages/icon-boiler-room.svg');
            }
        }
    }

    &.-dance-hall {
        &::before {
            background-image: url('../../images/stages/icon-dance-hall.svg');

            @supports (mask-image: url('../../images/stages/icon-dance-hall.svg')) {
                background-image: none;
                mask-image: url('../../images/stages/icon-dance-hall.svg');
            }
        }
    }

    &.-marquee {
        &::before {
            background-image: url('../../images/stages/icon-marquee.svg');

            @supports (mask-image: url('../../images/stages/icon-dance-hall.svg')) {
                background-image: none;
                mask-image: url('../../images/stages/icon-marquee.svg');
            }
        }
    }

    &.-castello {
        &::before {
            background-image: url('../../images/stages/icon-castello.svg');

            @supports (mask-image: url('../../images/stages/icon-dance-hall.svg')) {
                background-image: none;
                mask-image: url('../../images/stages/icon-castello.svg');
            }
        }
    }

    &.-club {
        &::before {
            background-image: url('../../images/stages/icon-club.svg');

            @supports (mask-image: url('../../images/stages/icon-dance-hall.svg')) {
                background-image: none;
                mask-image: url('../../images/stages/icon-club.svg');
            }
        }
    }

    &.-booth {
        &::before {
            background-image: url('../../images/stages/icon-booth.svg');

            @supports (mask-image: url('../../images/stages/icon-dance-hall.svg')) {
                background-image: none;
                mask-image: url('../../images/stages/icon-booth.svg');
            }
        }
    }

    &.-lift {
        &::before {
            background-image: url('../../images/stages/icon-lift.svg');

            @supports (mask-image: url('../../images/stages/icon-dance-hall.svg')) {
                background-image: none;
                mask-image: url('../../images/stages/icon-lift.svg');
            }
        }
    }

    &.-secret-room {
        &::before {
            background-image: url('../../images/stages/icon-secret-room.svg');

            @supports (mask-image: url('../../images/stages/icon-dance-hall.svg')) {
                background-image: none;
                mask-image: url('../../images/stages/icon-secret-room.svg');
            }
        }
    }

    &.-backyard {
        &::before {
            background-image: url('../../images/stages/icon-backyard.svg');

            @supports (mask-image: url('../../images/stages/icon-backyard.svg')) {
                background-image: none;
                mask-image: url('../../images/stages/icon-backyard.svg');
            }
        }
    }

    &.-vall-ey {
        &::before {
            background-image: url('../../images/stages/icon-vall-ey.svg');

            @supports (mask-image: url('../../images/stages/icon-vall-ey.svg')) {
                background-image: none;
                mask-image: url('../../images/stages/icon-vall-ey.svg');
            }
        }
    }
}

.lineup-stage__content {
    @extend .container-inner;
    align-items: center;
    display: flex;
    flex-basis: calc(100% / 3 * 2);
    font-weight: var(--font-weight-extra-bold);
    justify-content: flex-start;
    padding-top: 4rem;
    padding-bottom: 4rem;
    font-size: var(--font-size-s);
}

.lineup-stage__list {
    max-width: 47.5rem;
    text-transform: uppercase;

    a {
        text-decoration: none;
        transition: none;
    }
}

.lineup-stage__list-item {
    display: block;
    line-height: 1.2;
    margin: 1rem 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.lineup-stage-act__timings {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-xs);
}