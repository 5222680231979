.header {
    //display: contents; // temporary quick fix; can have a11y issues (https://hiddedevries.nl/en/blog/2018-04-21-more-accessible-markup-with-display-contents)
}

.header__top {
    background: var(--color-black);
    top: calc(var(--tape-height) * var(--number-of-sticky-tapes));
    z-index: var(--z-index-sticky-menu);

    /*@supports (position: sticky) {
        position: sticky;
    }*/

    @media (--show-mobile-menu) {
        position: fixed;
        width: 100%;
    }

    .menu-toggle {
        @include responsive-size(margin-right, $inner-container-padding-min * -1, $inner-container-padding-max * -1);
    }

    .-is-mobile-menu-open & {
        @media (--show-mobile-menu) {
            background-color: var(--color-action);
            color: var(--color-black);
        }
    }
}

.header__top-inner {
    align-items: center;
    display: flex;
    height: var(--tape-height);
    justify-content: space-between;
}

.header__top-right {
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-end;
    margin-left: 5rem;

    .social-icons {
        margin-bottom: 0;
    }

    @media (--show-mobile-menu) {
        margin-left: 0;
    }

    > *:not(:last-child) {
        margin-right: 5rem;
    }

    @media (--show-mobile-menu) {
        display: none;
    }
}

.header__banner {
    margin-bottom: -1px;

    @media (--show-mobile-menu) {
        .-is-mobile-menu-open & {
            display: none;
        }
    }
}

.header__bottom {
    /*@supports (position: sticky) {
        position: sticky;
    }*/
    background-color: var(--color-black);
    top: calc(var(--tape-height) * calc((var(--number-of-sticky-tapes) + 1))); // +1 menu for menu
    z-index: var(--z-index-sticky-menu);

    @media (--show-mobile-menu) {
        position: fixed;
        width: 100%;

        .-is-mobile-menu-open & {
            height: calc(100% - (var(--number-of-sticky-tapes) + 1) * var(--tape-height)); // iphone <= 8 chin menus + 2 x 50px top bars
        }
    }
}

.header__bottom-inner {
    align-items: center;
    border-top: 1px solid var(--color-white);
    display: flex;
    height: var(--tape-height);
    justify-content: space-between;

    &.-no-border {
        border-top: 0;
    }

    @media (--show-mobile-menu) {
        border-top: 0;
        display: none;
        padding-bottom: 2.5em;
        padding-top: 2em;

        .-is-mobile-menu-open & {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            overflow: scroll;

            > *:not(:last-child) {
                margin-bottom: 3vh;
            }

            > *:nth-last-child(2) {
                flex: 1 0 auto;
                justify-content: flex-start;
            }
        }
    }
}

.header__bottom-mobile {
    display: none;

    .social-icons {
        margin-bottom: 1em;
    }

    @media (--show-mobile-menu) {
        display: block;
    }
}
