@custom-media --band-social-shrink (width < 720px);
@custom-media --band-social-one-line (width >= 720px);

.band-social {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
}

.band-social__item {
    background-color: var(--color-white);
    border-radius: 50%;
    color: var(--color-black);
    display: flex;
    height: 5.7rem;
    margin: 0 2.1rem 4.3rem;
    position: relative;
    transition: background-color .2s ease-in-out;
    width: 5.7rem;

    @media (--band-social-one-line) {
        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    @media (--band-social-shrink) {
        @include responsive-size(margin-bottom, 30, 43, 320, 720);
        @include responsive-size(margin-right, 15, 22, 320, 720);
        @include responsive-size(margin-left, 15, 22, 320, 720);
        @include responsive-size(width, 40, 57, 320, 720);
        @include responsive-size(height, 40, 57, 320, 720);
    }

    svg {
        height: 60%;
        margin: auto;
        position: relative;
        width: 60%;
    }

    &:hover, &:focus {
        background-color: var(--color-action);
    }
}
