/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */
.h-hidden {
    display: none !important;
    visibility: hidden;
}

/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 * Allow the element to be focusable when navigated to via the keyboard: h5bp.com/p
 */
%h-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: .1rem;
    margin: -.1rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: .1rem;
}

.h-hide-mobile {
    @media (--show-mobile-menu) {
        display: none;
    }
}

.h-visually-hidden {
    @extend %h-visually-hidden;
}

.h-visually-hidden--focusable {
    @extend %h-visually-hidden;

    &:active, &:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}
