:root {
    --image-links-ratio-normal: 100%;
    --image-links-ratio-in-between: 75%;
    --image-links-ratio-wide: 50%;
}

@custom-media --image-links-columns-2-auto-rows (width > 530px);
@custom-media --image-links-columns-2-switch (width > 850px);
@custom-media --image-links-columns-3-switch (width > 850px);
@custom-media --image-links-columns-4-switch (width > 1000px);
@custom-media --image-links-columns-4-2-switch (width > 480px) and (width <= 1000px);
@custom-media --image-links-auto-columns-4-max (width >= 1600px);
@custom-media --image-links-auto-columns-3-max (width >= 1280px);
@custom-media --image-links-headliners-landscape (width >= 960px);
@custom-media --image-links-headliners-1-column (width < 640px);
@custom-media --image-links-mobile (width <= 480px);
@custom-media --image-links-live-1-column (width <= 960px);

.image-links {
    display: grid;
    position: relative;

    &.-color-line-up {
        color: var(--color-line-up);
    }

    &.-border {
        border-bottom: .4rem solid currentColor;
        border-top: .4rem solid currentColor;
    }

    &.-max-columns-2 {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        @media (--image-links-columns-2-auto-rows) {
            grid-auto-rows: 1fr;
        }

        @media (--image-links-columns-2-switch) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
        }
    }

    &.-max-columns-3 {
        grid-template-columns: 1fr;

        @media (--image-links-columns-3-switch) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &.-max-columns-4 {
        grid-template-columns: 1fr;

        @media (--image-links-columns-4-2-switch) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (--image-links-columns-4-switch) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &.-auto-columns {
        grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));

        @media (--image-links-auto-columns-4-max) {
            grid-template-columns: repeat(4, 1fr);
        }

        &.-large {
            @media (--image-links-auto-columns-3-max) {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }

    &.-headliners {
        grid-template-columns: repeat(2, 1fr);

        @media (--image-links-headliners-1-column) {
            display: block;
        }
    }

    @media (--image-links-columns-2-switch) {
        &.-shift-order .image-links__item {
            &:nth-child(1) {
                order: 1;
            }

            &:nth-child(2) {
                order: 2;
            }

            &:nth-child(3) {
                order: 4;
            }

            &:nth-child(4) {
                order: 3;
            }

            &:nth-child(5) {
                order: 5;
            }

            &:nth-child(6) {
                order: 6;
            }

            &:nth-child(7) {
                order: 8;
            }

            &:nth-child(8) {
                order: 7;
            }
        }
    }
}

.image-links__item {
    @include responsive-size(font-size, 21, 24);
    display: block;
    font-weight: var(--font-weight-extra-bold);
    margin: 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: uppercase;

    img {
        opacity: .7;
        transition: opacity .2s ease-in-out;

        @include target-ie() {
            height: 100%;
        }
    }

    &.-no-overlay {
        img {
            opacity: 1;
        }
    }

    &.-green {
        background-color: var(--color-green);
        color: var(--color-black);
    }
}

a.image-links__item:not(.-no-overlay) {
    &:hover, &:focus, &:active {
        outline: none;

        img {
            opacity: .85;
        }

        &.-has-hover-content {
            .image-links__item-content {
                display: none;
            }
        }

        .image-links__item-content-hover {
            display: block;
        }
    }
}

.image-links__item-content {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 2em;
    position: absolute;
    top: 0;
    width: 100%;

    @media (--image-links-mobile) {
        padding: 1em;
    }

    > div:not([class]) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.image-links__item-content-hover {
    background-color: rgba(0, 0, 0, .7);
    color: var(--color-yellow);
    display: none;
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-medium);
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 12.8%;
    position: absolute;
    text-align: left;
    text-transform: none;
    top: 0;
    width: 100%;
}

.image-links__item-hover-title {
    font-size: var(--font-size-m);
    line-height: 1.25;
    margin-top: 0;
    text-transform: uppercase;
}

.image-links__item-sideinfo {
    align-items: center;
    display: flex;
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-medium);
    height: 100%;
    justify-content: center;
    padding-bottom: 5rem;
    padding-top: 5rem;
    text-align: left;
    text-transform: none;
    @include responsive-size(padding-left, $inner-container-padding-min, $inner-container-padding-max);
    @include responsive-size(padding-right, $inner-container-padding-min, $inner-container-padding-max);

    @media (--image-links-columns-2-auto-rows) {
        padding-bottom: 0;
        padding-top: 0;
    }

    > article {
        max-width: 51rem;
        width: 100%;
    }
}
