%form__select {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 0;
    color: var(--text-color-primary);
    cursor: pointer;
    display: block;
    height: 4rem; // The height of a normal input field
    line-height: normal;
    padding: 0 0 0 calc(1em - 5px);
    width: 100%;

    &:focus {
        background-color: var(--color-white);
        border-color: var(--color-primary);
        outline: none;
    }
}

select:not([class]), .form__select {
    @extend %form__select;
}
