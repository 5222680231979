.form__message {
    color: var(--color-action);
    display: block;
    font-style: italic;
    line-height: 1;
    padding: .5em 0;

    .form__entry--invalid & {
        color: var(--color-pink);
        display: block;

        &::before {
            content: '* ';
        }
    }
}
