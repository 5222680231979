.schedule-stage-menu {
    background-color: var(--color-black);
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 6rem;
    padding-top: 12rem;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity .3s ease;
    z-index: 70;
    @include responsive-size(padding-left, $inner-container-padding-min, $inner-container-padding-max);
    @include responsive-size(padding-right, $inner-container-padding-min, $inner-container-padding-max);

    &::-webkit-scrollbar {
        height: 1rem;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--color-black);
        border-radius: .5rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-black);
        border-radius: .5rem;
    }

    &.-open {
        opacity: 1;
        pointer-events: auto;
    }
}

.schedule-stage-menu__close {
    color: var(--color-white);
    font-size: 2rem;
    position: absolute;
    right: 0;
    text-decoration: none;
    top: 2rem;

    svg {
        color: var(--color-white);
        height: 2.4rem;
        width: 2.4rem;
    }

    &:hover, &:focus {
        text-decoration: none;
    }
}

.schedule-stage-menu__title {
    color: var(--schedule-color);
    font-size: 2.8rem;
    letter-spacing: 0;
    line-height: 1.02;
    margin-bottom: 3rem;
}

.schedule-stage-menu__link {
    align-items: center;
    color: var(--color-white);
    display: flex;
    font-size: 2.2rem;
    letter-spacing: 0;
    line-height: 1.36;
    padding: 1.2rem 0 .4rem;
    text-decoration: none;

    svg {
        margin-right: 2rem;
        width: 2rem;
    }

    &:hover, &:focus {
        color: var(--schedule-color);
    }
}

.schedule-stage-menu__list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        margin: 0 auto 2rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.schedule-stage-menu__image {
    height: 1.5rem;
    margin-right: 1.8rem;

    .-boiler-room & {
        height: 1.75rem;
    }

    .-lift & {
        height: 1.8rem;
    }
}

@media (--schedule-hours-desktop) {
    .schedule-stage-menu {
        display: none;
    }
}
