@custom-media --band-header-move-button (width > 768px);

.band-header {
    position: relative;
}

.band-header__image {
    margin: 0;
    overflow: hidden;
    position: relative;
}

.band-header__image-title {
    align-items: center;
    background-color: rgba(0, 0, 0, .3);
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    height: 101%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    @include responsive-size(font-size, 18, 30);
    @include responsive-size(padding-left, $inner-container-padding-min, $inner-container-padding-max);
    @include responsive-size(padding-right, $inner-container-padding-min, $inner-container-padding-max);

    > b {
        display: block;
        font-weight: var(--font-weight-extra-bold);
        margin-bottom: .4rem;
        text-transform: uppercase;
    }
}

.band-header__image-acts {
    margin-bottom: 1em;
}

@media (--band-header-move-button) {
    .band-header__back-button {
        position: absolute;
        top: 5.5rem;
        @include responsive-size(left, $inner-container-padding-min, $inner-container-padding-max);
    }
}

.band-header__more-link {
    background-image: url('../../images/arrow-white.svg');
    height: 2rem;
    left: 50%;
    overflow: hidden;
    position: absolute;
    text-indent: -999px;
    transform: translateX(-50%) rotate(90deg);
    transition: background-color .2s ease-in-out;
    width: 1.1rem;

    @supports (mask-image: url('../../images/arrow.svg')) {
        background-color: white;
        background-image: none;
        mask-image: url('../../images/arrow.svg');
        mask-repeat: no-repeat;
        mask-size: auto 100%;

        &:hover, &:focus, &:active {
            background-color: var(--color-action);
        }
    }

    @media (--band-header-move-button) {
        bottom: 30%;
    }
}
