%link {
    color: inherit;
    transition: color .2s ease-in-out;

    &:hover, &:focus, &:active {
        text-decoration: none;
    }
}

a:not([class]) {
    @extend %link;
}

a[href^='mailto']:not([class]) {
}

a[rel='external']:not([class]) {
}

a[href^='tel']:not([class]) {
    color: inherit;
    cursor: inherit;
    text-decoration: inherit;

    &:hover, &:focus, &:active {
        color: inherit;
        cursor: inherit;
        text-decoration: inherit;
    }
}
