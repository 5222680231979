@custom-media --categories-menu-line-up-mobile (width < 950px);
@custom-media --categories-menu-switch-to-select (width < 1000px);

:root {
    --categories-menu-color: var(--color-green);
}

.categories-menu {
    background-color: var(--categories-menu-color);
    color: var(--color-black);
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.2rem;
    max-width: var(--outer-container-max-width);
    position: relative;
    width: 100%;

    @include responsive-size(padding-left, $inner-container-padding-min, $inner-container-padding-max);
    @include responsive-size(padding-right, $inner-container-padding-min, $inner-container-padding-max);

    @media (--show-mobile-menu) {
        scroll-margin-top: calc(2 * var(--tape-height));
    }

    &.-line-up {
        --categories-menu-color: var(--color-line-up);
        margin-top: 0;

        &.-donderdag {
            --categories-menu-color: var(--color-lineup-thursday);
        }

        &.-vrijdag {
            --categories-menu-color: var(--color-lineup-friday);
        }

        &.-zaterdag {
            --categories-menu-color: var(--color-lineup-saturday);
        }

        &.-zondag {
            --categories-menu-color: var(--color-lineup-sunday);
        }

        .categories-menu__item {
            &.-is-active::after {
                border-top-color: var(--categories-menu-color);
            }
        }
    }

    &.-switch-to-select {
        @media (--categories-menu-switch-to-select) {
            .categories-menu__inner {
                justify-content: center;
            }

            .categories-menu__list {
                background-color: var(--color-white);
                display: flex;
                flex-direction: column;
                position: absolute;
                top: calc(50% - 2rem);
                width: 22.2rem;
                z-index: 1;

                @include target-ie() {
                    transform: translateX(-50%);
                }

                &::after {
                    background-image: url('../../images/options.svg');
                    background-size: 100%;
                    content: '';
                    display: block;
                    height: 2rem;
                    position: absolute;
                    right: 1.4rem;
                    top: 2rem;
                    transform: translateY(-50%);
                    width: 2.5rem;
                }

                .categories-menu__item {
                    display: none;
                    padding: 0;

                    &:first-child {
                        border-top: 0;
                    }

                    &.-is-active {
                        box-sizing: border-box;
                        color: var(--color-black);
                        cursor: pointer;
                        display: flex;
                        order: -1;

                        &::after {
                            content: none;
                        }
                    }

                    &:not(.-is-active) {
                        .categories-menu__item-link {
                            font-size: var(--font-size-m);
                            font-weight: var(--font-weight-medium);
                            line-height: 1;
                            padding: .9rem 1.8rem;
                            text-transform: none;
                        }
                    }
                }

                .categories-menu__item-link {
                    padding: .7rem 4.5rem .5rem 1.8rem;
                    transition: color .2s ease-in-out;

                    &:hover, &:focus, &:active {
                        color: var(--color-black);
                    }
                }

                &.-is-open {
                    .categories-menu__item {
                        display: flex;

                        :not(:first-child) {
                            border-top: 1px solid var(--color-black);
                        }
                    }
                }
            }
        }
    }
}

.categories-menu__inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 11.4rem;
    position: relative;

    @include responsive-size(margin-left, -3, -40);
    @include responsive-size(margin-right, -3, -40);

    @media (--categories-menu-line-up-mobile) {
        &.-no-lineup-menu {
            justify-content: center;
        }

        &:not(.-no-lineup-menu) {
            flex-direction: column;
            justify-content: flex-start;
            min-height: 12.5rem;
        }
    }
}

.categories-menu__item {
    display: inline-block;
    padding-bottom: 1.6rem;
    padding-top: 1.7rem;
    position: relative;
    z-index: 1;

    @include responsive-size(padding-left, 2, 40);
    @include responsive-size(padding-right, 2, 40);

    &.-is-active {
        color: var(--color-white);

        &::after {
            @include triangle(down, var(--color-green), 1.7rem);
            content: '';
            left: 50%;
            position: absolute;
            top: 8.5rem;
            transform: translateX(-50%);

            @media (--categories-menu-line-up-mobile) {
                display: none;
            }
        }
    }

    @media (--categories-menu-line-up-mobile) {
        @include responsive-size(padding-left, 2, 40, 320, 700);
        @include responsive-size(padding-right, 2, 40, 320, 700);
    }
}

.categories-menu__item-link {
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-extra-bold);
    text-decoration: none;
    text-transform: uppercase;
    transition: color .2s ease-in-out;

    &:hover, &:focus, &:active {
        color: var(--color-white);
    }
}
