@custom-media --partners-mobile (width < 410px);

.partners {
    /* autoprefixer grid: autoplace */
    display: grid;
    gap: .5rem;
    grid-template-columns: repeat(3, 18rem); // for IE 11
    grid-template-rows: repeat(12, auto); // for IE 11
    justify-content: center;
    margin-bottom: 2em;

    @supports (display: grid) {
        grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
        grid-template-rows: auto;
    }

    @media (--partners-mobile) {
        grid-template-columns: 19rem;
    }

    &:not(:first-child) {
        margin-top: 1em;
    }
}

.partners__item {
    background-color: var(--color-white);
    display: block;
    text-align: center;
}
