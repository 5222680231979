/* Max site width without padding */
.container {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--outer-container-max-width);
    position: relative;
    width: 100%;
}

/* Max content width with side padding */
.container-inner {
    @extend .container;
    @include responsive-size(padding-left, $inner-container-padding-min, $inner-container-padding-max);
    @include responsive-size(padding-right, $inner-container-padding-min, $inner-container-padding-max);
}

.container-text {
    @extend .container;
    max-width: var(--text-container-max-width);

    &.-no-margin {
        margin-left: 0;
        margin-right: 0;
    }

    figure {
        margin: 0 0 1em 0;

        &.-center {
            text-align: center;
        }

        &.-right {
            text-align: right;
        }
    }
}

.container-form {
    @extend .container;
    max-width: var(--form-container-max-width);
}

.container-button {
    margin-bottom: -2rem;
    padding: 5.8rem;
    text-align: center;
    @include responsive-size(padding-left, $inner-container-padding-min, $inner-container-padding-max);
    @include responsive-size(padding-right, $inner-container-padding-min, $inner-container-padding-max);

    .button {
        margin-left: .8rem;
        margin-right: .8rem;
    }

    &.-no-padding-top {
        padding-top: 0;
    }

    &.-small-padding-top {
        padding-top: 2.9rem;
    }
}
