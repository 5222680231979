@custom-media --footer-equal-columns (width < 1270px);
@custom-media --footer-one-column (width < 560px);

.footer__main {
    /* autoprefixer grid: autoplace */
    display: grid;
    font-weight: var(--font-weight-semi-bold);
    gap: 7rem 3rem;
    grid-template-columns: auto repeat(3, 28.5rem);
    grid-template-rows: auto; // IE fix
    padding-bottom: 4rem;
    padding-top: 4rem;

    @media (--footer-equal-columns) {
        /* autoprefixer grid: autoplace */
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto); // IE fix
    }

    @media (--footer-one-column) {
        /* autoprefixer grid: autoplace */
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, auto); // IE fix
        row-gap: 4rem;
    }

    h3:not([class]) {
        color: var(--color-green);
        font-size: var(--font-size-m);
        font-weight: var(--font-weight-extra-bold);
        margin-bottom: 2em;
    }

    a:not([class]) {
        text-decoration: none;

        &:hover, &:focus, &:active {
            color: var(--color-action);
            text-decoration: none;
        }
    }

    address:not([class]) { // Align with link lists
        line-height: 2;
        margin-top: -.3em;
    }
}

.footer__main-col {
    &:first-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    > * {
        margin-bottom: 0;
    }
}

.footer__meta {
    background: var(--color-white);
    color: var(--color-black);
}

.footer__copy {
    background-color: var(--color-green);
    color: var(--color-black);
    text-transform: uppercase;

    small {
        font-size: var(--font-size-xs);
        line-height: 1.7;
    }

    a {
        color: var(--color-black);
    }
}

.footer__copy-inner {
    align-items: center;
    display: flex;
    font-weight: var(--font-weight-semi-bold);
    justify-content: center;
    min-height: var(--tape-height);
    padding-bottom: 1.75rem;
    padding-top: 1.75rem;
    text-align: center;
}
