.h-text-align-center {
    text-align: center;
}

.h-text-border {
    border: .4rem solid var(--color-white);
    padding: 2rem;
    margin-bottom: 2em;

    > *:last-child {
        margin-bottom: 0;
    }
}

.h-text-font-weight-normal {
    font-weight: normal;
}