.nav {
    display: flex;
    justify-content: space-between;

    li {
        &.-is-active {
            a {
                color: var(--color-action);
            }
        }

        a {
            text-decoration: none;
            text-transform: uppercase;

            &:hover, &:focus, &:active {
                color: var(--color-action);
            }
        }
    }

    @media (--show-mobile-menu) {
        flex-direction: column;
    }

    &.-primary {
        font-size: var(--font-size-m);
        font-weight: var(--font-weight-extra-bold);

        li {
            @media (--show-mobile-menu) {
                @include responsive-size(margin-bottom, 20, 50);
            }

            &:not(:last-child) {
                @include responsive-size(margin-right, 40, 143, 910);
            }
        }
    }

    &.-secondary {
        li {
            @media (--show-mobile-menu) {
                @include responsive-size(margin-bottom, 10, 30);
            }

            &:not(:last-child) {
                @include responsive-size(margin-right, 30, 50, 910);
            }
        }
    }
}

.nav-wrapper {
    display: flex;
    justify-content: space-between;
}
