@custom-media --page-one-column (width < 980px);
@custom-media --page-multi-column (width > 981px);

.page {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin-top: 5.5rem;

    @include responsive-size(padding-bottom, 20, 60);

    &.-with-menu {
        justify-content: flex-end;

        @media (--page-one-column) {
            justify-content: center;
        }

        .page-content {
            @media (--page-multi-column) {
                min-width: 52rem;
                width: auto;
            }
        }
    }

    &.-align-center {
        justify-content: center;
    }

    .page-menu {
        @include responsive-size(margin-right, 20, 110);
    }

    @media (--page-one-column) {
        display: block;

        .page-menu {
            margin: 3em auto;
        }
    }
}
