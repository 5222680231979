@mixin triangle($direction: up, $color: black, $size: .6rem) {
    display: inline-block;
    height: 0;
    width: 0;

    @if $direction == left {
        border-bottom: $size solid transparent;
        border-left: 0;
        border-right: $size solid $color;
        border-top: $size solid transparent;
    }
    @else if $direction == right {
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        border-right: 0;
        border-top: $size solid transparent;
    }
    @else if $direction == up {
        border-bottom: $size solid $color;
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: 0;
    }
    @else if $direction == down {
        border-bottom: 0;
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
    }
}
