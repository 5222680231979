@custom-media --cta-stretch-image (width > 880px);

:root {
    --cta-max-height: 54.6rem;
}

.cta {
    background-position: center;
    box-sizing: border-box;
    position: relative;

    &.-spotify {
        background-color: var(--color-green);
        background-image: url('../../images/cta/bg-spotify.svg');
        background-repeat: repeat-x;
        background-size: 11.2rem 101%;
        height: 100%;

        iframe {
            @include responsive-size(height, 291, 645);
            @include responsive-size(width, 291, 645);
        }
    }

    &.-videos {
        background-color: var(--color-blue);
        background-image: url('../../images/cta/bg-video.svg');
        background-repeat: repeat-x;
        background-size: auto 100%;

        .cta__content {
            min-height: 46rem;
        }
    }

    &.-countdown {
        background-color: var(--color-purple);

        .cta__image {
            max-width: 28vw;
        }

        .cta__background {
            background-image: url('../../images/cta/bg-countdown.svg');
        }

        @media (max-width: 550px) {
            .cta__content {
                max-width: 32rem;
            }
        }
    }
}

.cta__background {
    &.-fixed-height {
        background-position: center;
        background-size: 90rem 100%;
        height: 25rem;

        @media (--cta-stretch-image) {
            background-size: contain;
            height: 0;
            padding-top: 28.44%;
        }

        @media (--exceeding-container-max) {
            height: var(--cta-max-height);
            padding-top: 0;
        }
    }
}

.cta__inner {
    &.-fixed-height {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.cta__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-bottom: 3rem;
    padding-top: 3rem;
    text-align: center;
}

.cta__title {
    @include font-size(3.8rem);
    display: block;
    font-weight: var(--font-weight-extra-bold);
    line-height: 1.25;
    margin-bottom: 1.5em;
    margin-top: 0;
    position: relative;
    text-transform: uppercase;

    &.-no-margin-bottom {
        margin-bottom: 0;
    }

    a {
        color: inherit;
        text-decoration: none;

        &:hover, &:focus, &:active {
            color: var(--color-secondary);
            text-decoration: none;
        }
    }
}
