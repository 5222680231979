.schedule-hours {
    display: none;
}

@media (--schedule-hours-desktop) {
    .schedule-hours {
        display: block;
        left: 0;
        max-width: 100%;
        position: absolute;
        right: 0;
        top: 11.3rem;
    }

    .schedule-hours__hour {
        border-top: .2rem solid var(--schedule-color);
        box-sizing: border-box;
        display: block;
        margin-left: 10rem;
        max-width: 100%;
        position: relative;

        &:last-child {
            height: 0 !important;
        }
    }

    .schedule-hours__label {
        color: var(--schedule-color);
        font-size: 2rem;
        font-weight: var(--font-weight-semi-bold);
        left: -5rem;
        position: absolute;
        top: 0;
        transform: translateY(-50%);
    }
}
