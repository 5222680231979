.schedule-stages {
    overflow: hidden;

    &.schedule-stages--repeated {
        display: none;
    }
}

.schedule-stage {
    margin: 3rem 0;
}

.schedule-stage__title {
    align-items: center;
    color: var(--schedule-color);
    display: flex;
    font-size: 2.1rem;
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: 0;
    line-height: 2;
    margin-bottom: 1.5rem;

    @media (--schedule-hours-mobile) {
        scroll-margin-top: calc(2 * var(--tape-height));
    }
}

.schedule-stage__acts {
    border-top: .01rem solid transparent;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
}

.schedule-stage__menu-button {
    appearance: none;
    background-color: transparent;
    border: 0;
    color: var(--color-white);
    font-family: var(--font-primary);
    font-size: 1.8rem;
    font-weight: var(--font-primary-weight-medium);
    outline: none;
    padding: .4rem .8rem;
}

@media (--schedule-hours-desktop) {
    .schedule-stage__menu-button {
        display: none;
    }

    .schedule-stages {
        min-width: 164rem;
        text-align: center;
    }

    .schedule-stage {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 1%;
        margin-top: 0;
        min-height: 1px;
        vertical-align: top;
        width: 11.5%;

        &:last-child {
            margin-right: 0;
        }
    }

    .schedule-stage__title {
        font-size: 2rem;
        height: 11.3rem;
        justify-content: center;
        line-height: 1;
        margin-bottom: 0;
        margin-top: 0;
    }
}
