:root {
    /* stylelint-disable declaration-colon-space-after  */
    --table-border-color:               var(--color-white);

    --table-head-color:                 var(--color-black);
    --table-head-background-color:      var(--color-green);

    --table-row-color:                  var(--color-white);
    --table-row-background-color-even:  var(--color-black);
    --table-row-background-color-odd:   var(--color-black);
    /* stylelint-enable */
}

@custom-media --table-less-padding (width < 720px);

table:not([class]), .table {
    border: 0;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 90%;
    margin-bottom: 2em;
    margin-top: 2em;
    max-width: 100%;
    table-layout: fixed;
    width: 100%;

    @media (--table-less-padding) {
        font-size: 80%;
    }

    tr {
        &:nth-child(even) {
            background-color: var(--table-row-background-color-even);
        }

        &:nth-child(odd) {
            background-color: var(--table-row-background-color-odd);
        }

        &:hover {
            td {
                color: var(--color-action);
            }
        }
    }

    th, td {
        border-bottom: 1px solid color-mod(var(--table-border-color) a(70%));
        color: var(--table-row-color);
        padding: .9em .6em;

        @media (--table-less-padding) {
            @include responsive-size(padding-left, 2, 6, 320, 720);
            @include responsive-size(padding-right, 2, 6, 320, 720);
            padding-bottom: .5em;
            padding-top: .5em;
        }
    }

    td {
        &:first-child {
            border-left: 1px solid var(--table-border-color);
        }

        &:last-child {
            border-right: 1px solid var(--table-border-color);
        }
    }

    tr:last-child td {
        border-bottom-color: var(--table-border-color);
    }

    th {
        background: var(--table-head-background-color);
        border-left: 1px solid var(--table-head-background-color);
        border-right: 1px solid var(--table-head-background-color);
        color: var(--table-head-color);
        text-align: left;
        text-transform: uppercase;
        vertical-align: middle;
    }

    tbody {
        th, td {
            text-align: left;
            vertical-align: top;
        }
    }

    thead {
        th {
            border-bottom: 0;
        }
    }
}
