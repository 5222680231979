.image-links--live {
    @extend .image-links;
    grid-auto-rows: 1fr;

    @media (min-width: 42rem) {
        grid-template-columns: repeat(auto-fill, minmax(42rem, 1fr));
    }

    img {
        height: 100%;
        width: 100%;
    }

    .image-links__item {
        font-weight: var(--font-weight-semi-bold);

        &.-has-act {
            font-weight: var(--font-weight-extra-bold);
        }
    }

    .image-links__item-content {
        > div:not([class]) {
            text-align: left;
        }
    }

    .image-links__meta {
        font-size: 1.3rem;
        position: absolute;
        top: 0;
        left: 0;
        color: var(--color-white);
        min-height: 3.2rem;
        display: flex;

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3.2rem;
            width: 3.2rem;

            &.-facebook, &.-youtube, &.-twitter, &.-instagram {
                background-color: var(--color-purple);
            }

            > svg {
                display: inline-block;
                height: 2.2rem;
                width: auto;
            }
        }

        &-live {
            display: flex;
            align-items: center;
            background-color: var(--color-red);
            padding: 0 .8rem;
            font-weight: var(--font-weight-semi-bold);
        }

        &-label {
            display: flex;
            align-items: center;
            padding: 0 .8rem;
            background-color: var(--color-black);
        }
    }

    .image-links__play-button {
        background-color: var(--color-yellow);
        border-radius: 50%;
        width: 5rem;
        height: 5rem;
        margin-bottom: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color .15s ease-in-out;

        &::before {
            content: '';
            display: inline-block;
            height: 0;
            width: 0;
            border-bottom: .7rem solid transparent;
            border-left: .9rem solid black;
            border-right: 0;
            border-top: .7rem solid transparent;
            position: relative;
            left: 2px;
        }
    }

    a:hover {
        .image-links__play-button {
            background-color: var(--color-blue);
        }
    }
}