.history-bands {
    margin: 0 auto;
}

.history-bands__title {
    color: var(--color-yellow);
    font-weight: var(--font-primary-weight-black);
    text-align: center;
    text-transform: uppercase;
}

.history-bands__list {
    color: var(--color-white);
    font-weight: var(--font-weight-extra-bold);
    letter-spacing: .4rem;
    line-height: 2;
    margin: 0 auto 5rem;
    max-width: 98rem;
    text-align: center;
    text-transform: uppercase;
    @include responsive-size(font-size, $font-size-s, $font-size-m);

    a {
        color: var(--color-yellow);
        text-decoration: none;

        &:hover, &:focus {
            color: var(--color-pink);
        }
    }
}
