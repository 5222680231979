.schedule-options {
    align-items: stretch;
    display: flex;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 10;
}

.schedule-options__actions {
    align-items: center;
    display: flex;
    height: 4.4rem;
}

.schedule-options__scroll {
    margin-left: auto;
}

.schedule-options__actions__print {
    //color: var(--color-dark-gray3);
    display: none;
    line-height: 1;
    text-decoration: none;
    transition: color .3s ease;

    svg {
        height: 2rem;
        width: 2rem;
    }

    &:hover, &:focus {
        //color: var(--color-green);
        text-decoration: none;
    }
}

@media (--schedule-hours-desktop) {
    .schedule-options__actions__print {
        display: block;
    }
}
