.sponsors {
    padding: 2rem 0;
    text-align: center;

    a {
        display: inline-block;
        padding: 2rem .5rem;

        img {
            margin: 0;
        }
    }
}
