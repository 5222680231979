@custom-media --faqs-widget-mobile (width < 500px);

.faqs-widget {
    background-color: var(--color-yellow);
    color: var(--color-black);
    margin-bottom: 6rem;
    position: relative;

    &.-tickets {
        background-color: var(--color-tickets);
    }

    &.-camping {
        background-color: var(--color-camping);
    }

    &.-line-up {
        background-color: var(--color-line-up);
    }
}

.faqs-widget__item {
    border-bottom: 1px solid var(--color-black);
}

.faqs-widget__item-heading {
    margin: 0;
}

.faqs-widget__item-heading-button {
    appearance: none;
    background-color: transparent;
    border: 0;
    display: block;
    font-size: 1.8rem;
    line-height: 1.62;
    margin: 0;
    padding: 1.45rem 4rem 1.45rem 3rem;
    position: relative;
    text-align: left;
    width: 100%;

    &::after {
        background-image: url('../../images/arrow.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        content: '';
        display: block;
        height: 2rem;
        position: absolute;
        right: 2.2rem;
        top: 50%;
        transform: rotate(90deg) translateX(-80%);
        transform-origin: center;
        transition: transform .4s ease-in-out;
        width: 1.2rem;

        @supports (mask-image: url('../../images/arrow.svg')) {
            background-color: currentColor;
            background-image: none;
            mask-image: url('../../images/arrow.svg');
            mask-repeat: no-repeat;
            mask-size: contain;
        }

        @media (--faqs-widget-mobile) {
            height: 1.2rem;
            right: 1.2rem;
            width: .7rem;
        }
    }

    &.-is-active {
        &::after {
            transform: rotate(-90deg) translateX(80%);
        }
    }

    &:focus, &:active {
        color: currentColor;
        outline: none;
    }

    @media (--faqs-widget-mobile) {
        font-size: 1.7rem;
        padding: 1.25rem 3rem 1.25rem 2.5rem;
    }
}

.faqs-widget__item-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height .4s ease-in-out;

    &.-is-active {
        max-height: 2000px;
    }
}

.faqs-widget__item-content-inner {
    padding: 1.95rem 3rem 1.45rem;

    @media (--faqs-widget-mobile) {
        padding: 1.55rem 2.5rem 1.25rem;
    }
}
