@custom-media --filter-selection-hide (width < 1150px);

.filter-selection {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    min-height: 11.6rem;

    @media (--filter-selection-hide) {
        display: none;
    }

    @include target-ie() {
        height: 11rem; // flexbox bugfix
    }
}

.filter-selection__title {
    display: inline-block;
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-extra-bold);
    margin-right: 4.7rem;

    @supports (mask-image: url('../../images/options.svg')) {
        background-color: white;
        height: 4.3rem;
        mask-image: url('../../images/options.svg');
        mask-size: 100%;
        overflow: hidden;
        text-indent: -999px;
        width: 5.4rem;
    }
}

.filter-selection__list {
    display: inline-block;
}

.filter-selection__item {
    display: inline-block;
}

.filter-selection__item__button {
    background-color: var(--color-white);
    border: 0;
    margin-bottom: 0;
    margin-right: 2.8rem;
    padding: 1rem 1.5rem .9rem;
    text-transform: uppercase;

    &::after {
        background-image: url('../../images/cross.svg');
        background-size: 100%;
        content: '';
        display: inline-block;
        height: 1.3rem;
        margin-left: 1.5rem;
        position: relative;
        top: -.1rem;
        width: 1.3rem;
    }
}

.filter-selection__clear {
    background-color: var(--color-black);
    border: 1px solid var(--color-white);
    color: var(--color-white);
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-extra-bold);
    margin-bottom: 0;
    margin-right: 2.8rem;
    padding: .6rem 1.5rem .4rem;
    text-decoration: none;
    text-transform: uppercase;

    &:hover, &:focus {
        text-decoration: none;
    }
}
