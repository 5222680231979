.tape {
    background-position: center;
    background-repeat: repeat-x;
    color: var(--color-black);
    display: block;
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-extra-bold);
    height: var(--tape-height);
    letter-spacing: -.02em;
    line-height: var(--tape-height);
    margin: 0 -2px;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: calc(100% + 4px);

    &.-big {
        height: var(--tape-big-height);

        > svg {
            // Correct 1px line
            height: calc(100% + 2px);
            min-width: 100%;
            position: relative;
            top: -1px;
        }
    }

    &.-sticky, &.-sticky2 {
        top: 0;
        z-index: var(--z-index-sticky-menu);
        /*@supports (position: sticky) {
            position: sticky;
        }*/

        @media (--show-mobile-menu) {
            position: fixed;
            width: 100%;
        }
    }

    &.-sticky2 {
        top: var(--tape-height);
    }

    &.-bgcolor-action {
        background-color: var(--color-action);
    }

    &.-bgcolor-line-up {
        background-color: var(--color-line-up);
    }

    &.-bgcolor-tickets {
        background-color: var(--color-tickets);
    }

    &.-bgcolor-sold-out {
        background-color: var(--color-sold-out);
    }

    &.-bgcolor-camping {
        background-color: var(--color-camping);
    }

    &.-bgcolor-urban-1 {
        background-color: var(--color-pink);
    }

    &.-bgcolor-urban-2 {
        background-color: var(--color-green);
    }

    &.-bgcolor-electro-1 {
        background-color: var(--color-green);
    }

    &.-bgcolor-electro-2 {
        background-color: var(--color-purple);
    }

    &.-bgcolor-pop-1 {
        background-color: var(--color-yellow);
    }

    &.-bgcolor-pop-2 {
        background-color: var(--color-pink);
    }

    &.-bgcolor-indie-1 {
        background-color: var(--color-pink);
    }

    &.-bgcolor-indie-2 {
        background-color: var(--color-brown);
    }

    &.-bgcolor-rock-1 {
        background-color: var(--color-brown);
    }

    &.-bgcolor-rock-2 {
        background-color: var(--color-purple);
    }

    &.-bgcolor-red {
        background-color: var(--color-red);
    }

    &.-bgcolor-blue {
        background-color: var(--color-blue);
    }

    &.-bgcolor-yellow {
        background-color: var(--color-yellow);
    }

    &.-bgcolor-green {
        background-color: var(--color-green);
    }

    &.-bgcolor-orange {
        background-color: var(--color-brown);
    }

    &.-bgcolor-pink {
        background-color: var(--color-pink);
    }

    &.-bgcolor-purple {
        background-color: var(--color-purple);
    }

    &.-bgcolor-instagram {
        background-color: #3f729b;
    }

    &.-bgcolor-facebook {
        background-color: #4267B2;
    }

    &.-bg-1, &.-bg-2, &.-bg-3, &.-bg-4, &.-bg-5 {
        display: flex;

        &::before, &::after {
            background-repeat: repeat-x;
            background-size: auto 100%;
            content: '';
            display: block;
            flex-grow: 1;
        }

        &::before {
            background-position: right;
            margin-right: 2.5rem;
        }

        &::after {
            background-position: left;
            margin-left: 2.5rem;
        }
    }

    &.-bg-1 {
        &::before, &::after {
            background-image: url('../../images/tape-bg-1.svg');
        }

        &::after {
            background-position: right;
            transform: scale(-1);
        }
    }

    &.-bg-2 {
        &::before, &::after {
            background-image: url('../../images/tape-bg-2.svg');
        }
    }

    &.-bg-3 {
        &::before, &::after {
            background-image: url('../../images/tape-bg-3.svg');
        }
    }

    &.-bg-4 {
        &::before, &::after {
            background-image: url('../../images/tape-bg-4.svg');
        }
    }

    &.-bg-5 {
        &::before, &::after {
            background-image: url('../../images/tape-bg-5.svg');
        }
    }

    &.-headliners {
        background-color: var(--color-line-up);
        background-image: url('../../images/tapes/headliners.svg');
        background-size: 33.3rem 100%;

        @media (--show-mobile-menu) {
            background-size: 28.7rem 100%;
        }
    }

    &.-subheadliners {
        background-color: var(--color-tickets);
        background-image: url('../../images/tapes/subheadliners.svg');
        background-size: 49.1rem 100%;

        @media (--show-mobile-menu) {
            background-size: 42.4rem 100%;
        }
    }
}

.tape-wrapper {
    display: block;
    position: relative;
    text-decoration: none;
    width: 100%;

    @media (max-width: 2000px) {
        overflow: hidden;
    }

    > svg {
        @media (max-width: 2000px) {
            min-width: 200rem;
        }
    }

    &.-double {
        min-height: 14.2rem;

        @media (--show-mobile-menu) {
            min-height: 11.2rem;
        }

        .tape:first-child {
            transform: rotate(.7deg);
            transform-origin: left;
        }

        .tape:last-child {
            position: absolute;
            top: 50%;
            transform: rotate(-1deg) translate3d(0, -50%, 0);
        }

        &.-inverse {
            .tape:first-child {
                transform: rotate(-.7deg);
                transform-origin: right;

                @media (--show-mobile-menu) {
                    transform-origin: center;
                }
            }

            .tape:last-child {
                transform: rotate(1deg) translate3d(0, -50%, 0);
            }
        }
    }

    &.-rotate-up, &.-rotate-down {
        margin-bottom: -6.2rem;
        min-height: 12.3rem;
        top: -6.2rem;
        z-index: 1;

        .tape {
            position: relative;
            top: 3.2rem;
            transform: rotate(-1.7deg);
        }
    }

    &.-rotate-down {
        margin-bottom: 0;
        margin-top: -6.9rem;
        top: 6.9rem;

        .tape {
            top: 2.7rem;
            transform: rotate(1.7deg);

            @media (--show-mobile-menu) {
                top: 3.4rem;
            }
        }
    }

    &.-margin-bottom {
        margin-bottom: 6rem;
    }
}

.tape__lines {
    fill: none;
    stroke: var(--color-black);
    stroke-linejoin: round;
}
