.page-menu {
    margin: 0 auto;
    max-width: 100%;
    width: 33.3rem;
}

.page-menu__item {
    margin-bottom: 1.2rem;
}

.page-menu__link {
    background-color: var(--color-action);
    border: 1px solid var(--color-action);
    color: var(--color-black);
    display: block;
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-extra-bold);
    line-height: 1;
    padding: .95rem 3rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &::after {
        background-image: url('../../images/arrow.svg');
        background-size: 100% 100%;
        content: '';
        display: block;
        height: 1.9rem;
        position: absolute;
        right: 1.2rem;
        top: 50%;
        transform: translateY(-50%);
        width: 1rem;

        @supports (mask-image: url('../../images/arrow.svg')) {
            background-color: currentColor;
            background-image: none;
            mask-image: url('../../images/arrow.svg');
            mask-repeat: no-repeat;
            mask-size: contain;
        }
    }

    &:hover, &:focus, &:active, &.-is-active {
        background-color: var(--color-pink);
        border-color: var(--color-pink);
        text-decoration: none;
    }

    &.-back {
        background-color: var(--color-black);
        border-color: var(--color-white);
        color: var(--color-white);

        &::after {
            left: 1.2rem;
            right: auto;
            transform: translateY(-50%) scaleX(-1);
        }

        &:hover, &:focus, &:active {
            background-color: var(--color-pink);
            border-color: var(--color-pink);
            color: var(--color-black);
        }
    }
}
