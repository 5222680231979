strong, b {
    font-weight: var(--font-weight-bold);
}

p {
    letter-spacing: .02rem;
    margin-bottom: 2em;
    margin-top: 0;

    &.-align-center {
        text-align: center;
    }
}
