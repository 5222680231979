@custom-media --band-meta-data-aside (width > 768px);

.band-meta-data {
    margin-bottom: 1.7em;
    margin-top: 0;
    text-align: left;
    @include responsive-size(font-size, 21, 25);

    dt {
        color: var(--color-line-up);
        font-weight: var(--font-weight-extra-bold);
        text-transform: uppercase;

        @media (--band-meta-data-aside) {
            display: inline-block;
        }
    }

    dd {
        margin-left: 0;

        @media (--band-meta-data-aside) {
            display: inline-block;
        }
    }
}
