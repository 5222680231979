.audio-player-trigger {
    appearance: none;
    background-color: var(--color-action);
    background-image: url('../../images/sound.svg');
    background-position: center;
    background-size: 70%;
    border: 0;
    cursor: pointer;
    height: 5rem;
    outline: none;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-indent: -999px;
    top: 0;
    transition: background-color .2s ease-in-out;
    width: 5rem;

    &.-playing {
        background-color: var(--color-action);
        background-image: url('../../images/pause.svg');
        background-size: 43%;

        &:active, &:focus {
            background-color: var(--color-action);
        }

        &:hover {
            background-color: var(--color-tickets);
        }
    }

    &:hover, &:active, &:focus {
        background-color: var(--color-tickets);
    }
}
