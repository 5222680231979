/* form__ENTRY */
%form__entry {
    margin: 0 0 1.5rem;
    position: relative;
}

.form__entry {
    @extend %form__entry;

    &--disabled {
        @extend %form__entry;

        .form__field,
        .form__label {
            cursor: default;
            opacity: .5;
        }

        .form__label--checkbox,
        .form__label--radio {
            &::before,
            &::after {
                background: var(--color-black);
            }
        }
    }

    &--invalid {
        @extend %form__entry;

        // see field.scss
        // see message.scss
    }
}
