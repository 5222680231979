body {
    @include font-smoothing(); // Disable font-smoothing on places where you don't want it
    font-size: var(--font-size-s);
    line-height: 1.5;

    @media (--show-mobile-menu) {
        padding-top: calc(calc(var(--number-of-sticky-tapes) + 1) * var(--tape-height)); // +1 for menu
    }

    .-is-mobile-menu-open & {
        @media (--show-mobile-menu) {
            height: 100%;
        }
    }
}
