.schedule-act {
    border-bottom: .1rem solid var(--color-black);
    box-sizing: border-box;
    color: var(--color-black);
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-semi-bold);
    margin-bottom: 2rem;
    overflow: visible;
    padding-bottom: 2rem;

    @media (--schedule-hours-mobile) {
        border-bottom: .2rem solid var(--schedule-color);
        color: var(--color-white);
    }

    &.-favorite {
        .schedule-act__favorite {
            svg path {
                fill: var(--color-white);
            }
        }
    }
}

@media (--schedule-hours-mobile) {
    .schedule-act {
        height: auto !important;
        margin-top: 0 !important;
    }
}

.schedule-act__content {
    display: flex;
}

.schedule-act__band {
    margin-right: 2rem;
}

.schedule-act__band__name {
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-semi-bold);
    margin: 0;

    a {
        text-decoration: none;
    }
}

.schedule-act__band__stage {
    margin: 0;
}

.schedule-act__favorite {
    align-items: center;
    color: var(--color-white);
    display: flex;
    flex: 0 0 auto;
    margin-left: auto;

    svg {
        height: 1.4rem;
        width: 1.6rem;
    }
}

@media (--schedule-hours-desktop) {
    .schedule-act {
        background-color: var(--schedule-color);
        margin-bottom: 0;
        min-height: 0;
        overflow: hidden;
        padding: .75rem 1rem;
        position: relative;

        &.-favorite {
            .schedule-act__favorite {
                svg path {
                    fill: var(--color-black);
                }
            }
        }

        &:hover, &:focus {
            cursor: pointer;
            overflow: visible;

            .schedule-act__content {
                background-color: inherit;
                margin: -.75rem -1rem;
                padding: .75rem 1rem;
            }
        }
    }

    .schedule-act__content {
        flex-direction: column;
    }

    .schedule-act__timings {
        align-items: center;
        flex-direction: row;
        margin-bottom: .5rem;
    }

    .schedule-act__start-time, .schedule-act__end-time {
        margin: 0;
    }

    .schedule-act__band__stage {
        display: none;
    }

    .schedule-act__favorite {
        color: var(--color-black);
        position: absolute;
        right: .75rem;
        top: 1rem;
    }
}
