@import "~@splidejs/splide/dist/css/splide.min.css";

:root {
    --plyr-color-main: '#FF9852';
}

.splide {
    margin: 3rem 3.6rem 4rem;

    figure {
        margin: 0;

        img {
            object-fit: cover;
        }
    }

    figcaption {
        padding: 2rem 0;
        font-size: 1.8rem;
    }
}

.splide__slide {
    > a {
        display: block;
        text-decoration: none;
    }
}

.splide__arrow {
    background: none;
    color: white;
    top: calc(50% - 3rem);
    opacity: .9;

    &[disabled] {
        display: none;
    }

    svg {
        width: 32rem;
        height: 32rem;
    }
}

.splide__arrow--prev {
    left: -3.6rem;
}

.splide__arrow--next {
    right: -3.6rem;
}
