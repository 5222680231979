@custom-media --ticket-blocks-2-columns (width < 770px);
@custom-media --ticket-blocks-1-column (width < 600px);

.ticket-blocks {
    /* autoprefixer grid: autoplace */
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    margin-bottom: 4em;
    max-width: 88.8rem;

    @media (--ticket-blocks-2-columns) {
        /* autoprefixer grid: autoplace */
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto;
    }

    @media (--ticket-blocks-1-column) {
        /* autoprefixer grid: autoplace */
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
    }

}

.ticket-blocks__item {
    align-items: center;
    background-color: var(--color-blue);
    color: var(--color-black);
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-extra-bold);
    justify-content: space-between;
    min-height: 35.7rem;
    padding: 2em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    @include target-ie() {
        display: block; // Fix flexbox issue with the padding
    }

    @media (--ticket-blocks-1-column) {
        min-height: 0;
    }

    > p {
        margin: 1em 0;
    }

    &.-alt {
        background-color: var(--color-green);
    }

    /*&:hover, &:focus, &:active {
        background-color: var(--color-pink);
        text-decoration: none;
    }*/
}

.ticket-blocks__item-title, .ticket-blocks__item-price {
    flex: 0 0 auto;
    font-size: var(--font-size-xl);

    > small {
        display: block;
        font-size: var(--font-size-s);
        font-weight: var(--font-weight-medium);
        text-transform: none;
    }
}
