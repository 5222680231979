.schedule-scroll-controls {
    display: none;
}

.schedule-scroll-controls__left, .schedule-scroll-controls__right {
    align-items: center;
    appearance: none;
    background-color: transparent;
    background-image: url('../../images/arrow-white.svg');
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    color: var(--color-white);
    height: 2.1rem;
    justify-content: center;
    outline: none;
    overflow: hidden;
    padding: .5rem 1rem;
    text-indent: -99rem;
    transition: background-color .2s ease;
    width: 3rem;

    @supports (mask-image: url('../../images/arrow.svg')) {
        background-color: var(--schedule-color);
        background-image: none;
        mask-image: url('../../images/arrow.svg');
        mask-position: center;
        mask-repeat: no-repeat;
    }

    &:hover, &:active, &:focus {
        opacity: .9;

        @supports (mask-image: url('../../images/arrow.svg')) {
            background-color: var(--color-white);
            opacity: 1;
        }
    }

    &[disabled] {
        opacity: .3;

        &:hover, &:active, &:focus {
            opacity: .3;
        }
    }
}

.schedule-scroll-controls__left {
    transform: scale(-1);
}

@media (--schedule-hours-desktop) {
    .schedule__scroll {
        -ms-overflow-style: none;
        margin-left: 10rem;
        margin-right: 0;
        overflow: hidden;
        position: relative;

        &.main-wrapper {
            overflow-x: scroll;
            overflow-y: hidden;
            padding-bottom: 2rem;

            &::-webkit-scrollbar {
                height: 1rem;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
                border-radius: .5rem;
            }

            &::-webkit-scrollbar-thumb {
                background-color: transparent;
                border-radius: .5rem;
            }
        }
    }

    .schedule-scroll-controls {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-top: 4.6rem;
        width: 10rem;

        &.-hidden {
            display: none;
        }
    }
}
